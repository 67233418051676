import React, { ReactElement } from 'react';
import { Link } from 'gatsby';
import { Box, Text } from 'rebass';
import { HomePageLayout } from '../../layouts/HomePage.layout';
import { PageSection } from '../../components/layout/_/PageSection/PageSection.component';
import { useTranslation } from 'react-i18next';

const PageDeleted = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <HomePageLayout>
      <PageSection>
        <Box>
          <Text>{t('pageDeletedHeading')}</Text>
          <Text>{t('pageDeletedHeadingSubText')}</Text>
          <Text textAlign="center" my={150}>
            {t('pageDeletedBodyText')} <Link to="/">{t('pageDeletedButtonText')}</Link>
          </Text>
        </Box>
      </PageSection>
    </HomePageLayout>
  );
};

export default PageDeleted;
